import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import mapboxgl from 'mapbox-gl';
import SEO from '../components/seo';
import Layout from '../components/layout';

import {
  H2White, ULWhite, Address, Input, TextArea, FormButton,
} from '../components/typography';

const StyledGrid = styled(Grid)`
  margin-bottom: 50px;
  max-width: 89rem;
  width: 100%;

  @media (min-width: 64em) {
    margin-bottom: 106px;
  }
`;

const StyledMap = styled.div`
  width: 100%;
  height: 560px;

  @media (max-width: 767.98px) {
    height: calc(560px/2);
  }
`;

const Contact = () => {
  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoidmFuYnJldyIsImEiOiJjazZzaXhnN3MwM3R4M3BvMzhpY3hicWtzIn0.ajAvk5-xtL1QM9kS4R5KhQ';

    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/vanbrew/ckakgvusg2gpo1jllz8qnzcty',
      center: [133.300836, -25.597409],
      zoom: 3,
    });

    const timer = setTimeout(() => {
      map.flyTo({
        zoom: 18.3,
        center: [151.210345, -33.879683],
        duration: 9000,
      });

      return () => clearTimeout(timer);
    }, 2000);
  }, []);

  return (
    <Layout>
      <SEO title="Contact" />
      <StyledGrid fluid>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <StyledMap id="map" />
          </Col>
        </Row>
      </StyledGrid>
      <StyledGrid fluid>
        <Row>
          <Col lg={4} md={6} sm={6} xs={12}>
            <H2White>brewster hjorth architects</H2White>
            <Address>
              level 1, 4-14 foster street,
              <br />
              {' '}
              surry hills 2010
            </Address>
            <ULWhite>
              <li><a href="tel:61282317100">t: +61 2 8231 7100</a></li>
              <li><a href="mailto:bha@brewsterhjorth.com.au">e: bha@brewsterhjorth.com.au</a></li>
              <li><a href="https://brewsterhjorth.com.au/">w: brewsterhjorth.com.au</a></li>
            </ULWhite>
          </Col>
          <Col lg={8} md={6} sm={6} xs={12}>
            <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>
                  Don’t fill this out:
                  {' '}
                  <input name="bot-field" />
                </label>
              </p>
              <Row>
                <Col lg={6} md={6} sm={6} xs={12}><Input type="text" name="firstName" placeholder="First Name" /></Col>
                <Col lg={6} md={6} sm={6} xs={12}><Input type="text" name="lastName" placeholder="Last Name" /></Col>
              </Row>
              <Input type="email" name="email" placeholder="Email" />
              <Input type="phone" name="phone" placeholder="Phone" />
              <TextArea name="message" placeholder="Message" rows="13" />
              <FormButton type="submit">Submit</FormButton>
            </form>
          </Col>
        </Row>
      </StyledGrid>
    </Layout>
  );
};

export default Contact;
